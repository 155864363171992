import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import Layout from "src/layouts/layout";

export const renderRoutes = routes => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          ></Route>
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: "/404",
    component: lazy(() => import("src/views/NotFoundView")),
  },
  {
    path: "/login",
    component: lazy(() => import("src/views/Auth/LoginWithMagicLink")),
  },
  {
    path: "/register",
    component: lazy(() => import("src/views/Register")),
  },
  {
    path: "/auth/authenticate",
    component: lazy(() => import("src/views/Auth/AuthMagicLink")),
  },
  {
    path: "/discover",
    layout: Layout,
    component: lazy(() => import("src/views/PublicOffices")),
  },
  {
    layout: Layout,
    path: "/public_offices/:office_id/posts/:id",
    component: lazy(() => import("src/views/Publics/post")),
  },
  {
    layout: Layout,
    path: "/public_offices/:office_id/posts",
    component: lazy(() => import("src/views/Publics/posts")),
  },
  {
    path: "/",
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        layout: Layout,
        path: "/",
        component: lazy(() => import("src/views/Feed")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/dashboard",
        component: lazy(() => import("src/views/Dashboard")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/feed",
        component: lazy(() => import("src/views/Feed")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/emails/thread/:thread_id",
        component: lazy(() => import("src/views/InboxWithDetails")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/emails/:folder",
        component: lazy(() => import("src/views/Inbox")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/sentbox",
        component: lazy(() => import("src/views/Inbox")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/tasks",
        component: lazy(() => import("src/views/Tasks")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/messages",
        component: lazy(() => import("src/views/Messages")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/office-profile",
        component: lazy(() => import("src/views/OfficeProfile")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/notifications",
        component: lazy(() => import("src/views/Notifications")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/account",
        component: lazy(() => import("src/views/Account")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/workflow/:workflow_id",
        component: lazy(() =>
          import("src/views/Workflow/components/WorkflowModal")
        ),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/workflow",
        component: lazy(() => import("src/views/Workflow")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/template",
        component: lazy(() => import("src/views/Template")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/issues/:id",
        component: lazy(() => import("src/views/Metadata/Detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/issues",
        component: lazy(() => import("src/views/Metadata/Issues")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/topics/:id",
        component: lazy(() => import("src/views/Metadata/Detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/topics",
        component: lazy(() => import("src/views/Metadata/Topics")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/bills/:id",
        component: lazy(() => import("src/views/Metadata/Detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/data/bills",
        component: lazy(() => import("src/views/Metadata")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/organization",
        component: lazy(() => import("src/views/Contacts/Organization")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/tag",
        component: lazy(() => import("src/views/Contacts/Tag")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/people/new",
        component: lazy(() => import("src/views/Contacts/components")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/people/:id/edit",
        component: lazy(() => import("src/views/Contacts/components")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/people/:id",
        component: lazy(() => import("src/views/Contacts/Detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/people",
        component: lazy(() => import("src/views/Contacts")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/office/setup",
        component: lazy(() => import("src/views/Office/NewOffice")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/office/plan",
        component: lazy(() => import("src/views/Office/PlanOffice")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/offices/:id/:code",
        component: lazy(() => import("src/views/Office/InvitedOffice")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/posts/new",
        component: lazy(() => import("src/views/Post/detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/posts/:id/edit",
        component: lazy(() => import("src/views/Post/detail")),
      },
      {
        // guard: AuthGuard,
        layout: Layout,
        path: "/posts/:id/detail",
        component: lazy(() => import("src/views/Post/particular")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/posts/search",
        component: lazy(() => import("src/views/Post/search")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/posts",
        component: lazy(() => import("src/views/Post")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/public_offices",
        component: lazy(() => import("src/views/PublicOffices")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/forecasts/:id/detail",
        component: lazy(() => import("src/views/Forecasts/detail")),
      },
      {
        guard: AuthGuard,
        layout: Layout,
        path: "/forecasts",
        component: lazy(() => import("src/views/Forecasts")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
